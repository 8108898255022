
// Fonts
//@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
//@import "variables";

// Bootstrap
//@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";






// Import normalize
//@import "bootstrap/normalize";

// Override Boostrap variables
@import "bootstrap-variables";

// Import Bootstrap source files
//@import "bootstrap/bootstrap";

// If you want you can import bootstrap scss files directly from node_modules or bower_components.
// To do this please remove @import "bootstrap/bootstrap"; and uncomment proper line.

// Import Bootstrap source files from node_modules
 @import "node_modules/bootstrap/scss/bootstrap";

// Import Bootstrap source files from bower_components
// @import "bower_components/bootstrap/scss/bootstrap";

// Import vendors styles
@import "vendors/vendors";

// Import core styles
@import "custom-variables";
@import "core/mixins";

// Additional typography
@import "core/typography";

// Animations
@import "core/animate";

// Components
@import "core/aside";
@import "core/avatars";
@import "core/badge";
@import "core/breadcrumb-menu";
@import "core/breadcrumb";
@import "core/buttons";
@import "core/callout";
@import "core/card";
@import "core/charts";
@import "core/dropdown";
@import "core/footer";
@import "core/grid";
@import "core/input-group";
@import "core/loading";
@import "core/modal";
@import "core/nav";
@import "core/navbar";
@import "core/progress";
@import "core/sidebar";
@import "core/switches";
@import "core/tables";
@import "core/widgets";

// Layout Options
@import "core/layout";

@import "core/others";

// Utility classes
@import "core/utilities";

// Temporary fixes
@import "core/temp";

// Right-to-left
@import "core/rtl";

// Custom styles
@import "custom";
