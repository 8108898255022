// Here you can add other styles
fieldset.fieldset {
  border: 2px solid #e4e5e6;
  margin-bottom: 1rem;
  padding: 0 1rem;
}

fieldset.fieldset legend {
  width: initial;
  margin: 0;
  padding: 0 1rem;
}